// Here you can add other styles

// ==================== Custom-sizeed avatar ==================== //

.avatar-xxbig {
  height: 150px;
  width: 150px;
}

.avatar-xbig {
  height: 120px;
  width: 120px;
}

.avatar-big {
  height: 100px;
  width: 100px;
}

.avatar-small {
  height: 50px;
  width: 50px;
}

.avatar-med {
  height: 75px;
  width: 75px;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.rounded-circle {
  border-radius: 50% !important;
}

// ==================== Dark overlay for avatar ==================== //

/* Container needed to position the overlay */
.overlayed-container {
  position: relative;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Black see-through */
  transition: 0.3s ease;
  color: white;
  text-align: center;
}

/* For centering the overlay text / icon */
.overlay-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* When you mouse over the container, overlay gets darker */
.overlayed-container:hover .overlay {
  background: rgba(0, 0, 0, 0.6);
}
